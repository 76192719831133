import React, { useState, useEffect } from 'react';
import { Col, Row, Pagination, Empty, Typography } from 'antd';
import { Translation } from 'react-i18next';
import { withRouter } from 'react-router';
import { partnership_form_answers } from '../../../mock/data';
import DailyLogsList from './DailyLogsList';
import LoadingIcon from '../../../elements/components/LoadingIcon';

const { Text } = Typography;

const DailyLogs = ({
  list,
  load,
  partnershipId,
  careGuideId,
  page,
  limit,
  order,
  total,
  isLoading,
  isGeneratingPDF,
  generatePDF,
  setPageDailyLogs,
  dates,
  mock = false,
  ...props
}) => {
  const [activeKey, setActiveKey] = useState([]);
  const [showFirstKey, setShowFirstKey] = useState(true);

  const onChangeCollapse = (key) => {
    setShowFirstKey(false);
    setActiveKey(key);
  };

  const onClickExportHandler = (partnershipId, careGuideId, id) => {
    generatePDF(partnershipId, careGuideId, id);
  };

  const isActiveKey = (key) => activeKey.includes(key);

  const onChange = (newPage, pageSize) => {
    setActiveKey([]);
    setShowFirstKey(false);
    setPageDailyLogs(newPage);
  };

  useEffect(() => {
    if (mock === false) {
      const { start = '', end = '' } = dates;
      if (start !== '' && end !== '') {
        setActiveKey([]);
        setShowFirstKey(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  useEffect(() => {
    if (mock === false) {
      load(partnershipId, careGuideId, page, limit, order, dates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careGuideId, partnershipId, page, limit, order, load, dates]);

  useEffect(() => {
    if (mock === false) {
      if (showFirstKey && page === 1 && total > 0) {
        setActiveKey([list[0]?.id]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFirstKey, page, total]);

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <LoadingIcon />
      </div>
    );
  }

  return (
    <Translation>
      {(t) => (
        <>
          <Row className="section-daily-dashboard">
            <Col flex="auto" className={`info-row`}>
              <>
                {(total > 0 && mock === false) || mock ? (
                  <>
                    <DailyLogsList
                      isActiveKey={isActiveKey}
                      list={mock ? partnership_form_answers : list}
                      mock={mock}
                      onClickExportHandler={onClickExportHandler}
                      partnershipId={partnershipId}
                      careGuideId={careGuideId}
                      activeKey={activeKey}
                      onChangeCollapse={onChangeCollapse}
                      setShowFirstKey={setShowFirstKey}
                      showFirstKey={showFirstKey}
                      setActiveKey={setActiveKey}
                    />
                    <br />
                    {(page === 1 && total <= limit && mock === false) ||
                    mock ? (
                      false
                    ) : (
                      <div style={{ textAlign: 'center' }}>
                        <Pagination
                          defaultCurrent={5}
                          style={{ textAlign: 'center' }}
                          current={mock ? 1 : page}
                          pageSize={mock ? 10 : limit}
                          position={['bottomCenter']}
                          total={mock ? 5 * 5 : total}
                          onChange={mock ? null : onChange}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div
                    style={{
                      height: '260px',
                      backgroundColor: 'white',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    <Empty
                      description={
                        <Text>
                          {t(
                            'care_guide_owners_cognito_empty_answers_description'
                          )}
                        </Text>
                      }
                    />
                  </div>
                )}
              </>
            </Col>
          </Row>
        </>
      )}
    </Translation>
  );
};

export default withRouter(DailyLogs);
